import axios from 'axios'

const baseURL = process.env.REACT_APP_AUTH_ENDPOINT || `localhost:3002`

const client = axios.create({baseURL})

interface captchaInput {
  token: string
}

export const postCaptcha = async (data: captchaInput) => {
  const response = await client.post(`/auth`, {
    token: data.token
  })
  return response
}