import "./App.css";
import { useMemo } from "react";

import Minter from "./Minter";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
  getMathWallet,
} from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { ThemeProvider, createTheme } from "@material-ui/core";
import { useMutation } from "react-query";
import ReCaptcha from "react-google-recaptcha";
import React from "react";
import { postCaptcha } from "./lib/services/google";

const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
  ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
  : undefined;

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);
const googleKey = process.env.REACT_APP_GOOGLE_CAPTCHA_KEY || ``;

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const enableCaptcha = true;
//something

const App = () => {
  const [solvedMonkeCaptcha, setSolvedMonkeCaptcha] = React.useState(false);
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const { mutate } = useMutation(postCaptcha);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSolletWallet(),
      getMathWallet(),
    ],
    []
  );

  const handleRecaptchaSolved = (responseKey: any) => {
    if (responseKey) {
      // const token = responseKey;

      // vVv api call to backend response is true call below vVv
      mutate(
        {
          token: responseKey,
        },
        {
          onSuccess: (resp: any) => {
            const data = resp.data;
            if (data?.response?.success) {
              setSolvedMonkeCaptcha(true);
              return;
            } else {
              setSolvedMonkeCaptcha(false);
            }
          },
          onError: () => {
            setSolvedMonkeCaptcha(false);
          },
        }
      );
    } else {
      setSolvedMonkeCaptcha(false);
    }
  };

  const handleRecaptchaError = () => {
    setSolvedMonkeCaptcha(false);
  };

  return (
    <div
      style={{
        height: `100vh`,
      }}
    >
      <div className="content-wrapper">
        <div>
          <ThemeProvider theme={theme}>
            <ConnectionProvider endpoint={endpoint}>
              <WalletProvider wallets={wallets} autoConnect>
                <WalletDialogProvider>
                  <div
                    style={{
                      display: `flex`,
                      flexDirection: `column`,
                      textAlign: `center`,
                      alignItems: `center`,
                      justifyContent: `center`,
                    }}
                  >
                    <div style={{ marginTop: `25%` }}>
                      <img
                        style={{ marginBottom: 10, borderRadius: 20 }}
                        alt={`monkeGif`}
                        src={`/monkes.gif`}
                        width={300}
                        height={300}
                      />
                    </div>
                    <div style={{ marginBottom: 50 }}>
                      <h1>Monke Mint Time!</h1>
                      <p>Get Hyped!!!</p>
                    </div>
                  </div>

                  <div
                    style={{
                      display: `flex`,
                      textAlign: `center`,
                      alignItems: `center`,
                      justifyContent: `center`,
                    }}
                  >
                    {enableCaptcha ? (
                      solvedMonkeCaptcha ? (
                        <Minter
                          candyMachineId={candyMachineId}
                          connection={connection}
                          startDate={startDateSeed}
                          txTimeout={txTimeout}
                          rpcHost={rpcHost}
                          onMint={handleRecaptchaError}
                        />
                      ) : (
                        <ReCaptcha
                          sitekey={googleKey}
                          size={`normal`}
                          onChange={handleRecaptchaSolved}
                          onError={handleRecaptchaError}
                          onExpired={handleRecaptchaError}
                        />
                      )
                    ) : (
                      <Minter
                        candyMachineId={candyMachineId}
                        connection={connection}
                        startDate={startDateSeed}
                        txTimeout={txTimeout}
                        rpcHost={rpcHost}
                        onMint={() => {}}
                      />
                    )}
                  </div>
                </WalletDialogProvider>
              </WalletProvider>
            </ConnectionProvider>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default App;
